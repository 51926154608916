@import '../../utilities';

.ProjectorScreen {
  position: relative;
  display: flex;
  // flex-wrap: wrap;
  // align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  // min-height: 100px;
  height: 250px;  // would be nice to have max-height: 100%, but parent whiteboard doesnt have
                  // set height, just min-height, which child max-height in percentage doesnt respect
  width: 80%;
  min-width: 240px;
  max-width: 400px;
  // min-width: 250px;
  // max-width: 350px;
  // flex: 1 0 0;
  // align-self: stretch;
  // justify-self: stretch;
  // background-color: #bddef4;

  &#{&}-preloading {
    // background-color: black;
    // opacity: 0;
  }

  &.fullscreen {
    // Almost full screen. Take away 10 px on each side.
    height: 330px; // min-height of whiteboard sm is 370 including 10px border on t/b; can't do 100% because parent whiteboard just has min-height, not actual height. So not truly fullscreen capabalities...
    // width: calc(100% - 20px);
    // min-width: calc(100% - 20px);
    // max-width: calc(100% - 20px);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  &-loading {
    // wanted to pass in duration in JS, but complicated...
    // really timer is 3-5s, but end part of opacity change all looks the same...
    animation: transition-to-opaque 7s forwards;
    text-align: center;
    background-color: #bddef4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: relative;
    &-countdown-text {
      margin: 10px 0;

    }

    // &-cover {
    //   background-color: white;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    // }

    // &-countdown {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   font-size: 20px;
    //   text-align: center;
    //   background-color: #bddef4;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;


  
    //   &-text {
    //     margin: 10px 0;
    //   }
    // }
  }

  &#{&}-loaded {
    opacity: 1;
    // background-color: rgba(36, 36, 36, 0.687);
    background-color: darken(#bddef4, 50%);
    // background-color: white;
    // transform: scale(0);
    // animation: to-scale-100 5s forwards;
  }

  // &.fullMovie {
  //   background-color: white;
  //   width: 100%;
  // }


  &-frame {
    position: absolute;
    height: 50%;
    width: 20%;
  }

  &-frame-tr {
    top: 0;
    right: 0;
    border-top: 5px solid #003540;
    border-right: 5px solid #003540;
  }

  &-frame-bl {
    bottom: 0;
    left: 0;
    border-left: 5px solid #003540;
    border-bottom: 5px solid #003540;
  }
}

@keyframes transition-to-opaque {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


// Toying with cool transtion once projector loaded...
// but way too complicated and end up having to animate non transform/opacity properties
// .ProjectorScreen-frame-bl {
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   animation: frame-to-bottom-left 5s forwards;
// }

// .ProjectorScreen-frame-tr {
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   animation: frame-to-top-right 5s forwards;
// }


// @keyframes to-scale-100 {
//   to {
//     transform: scale(1);
//   }
// }

// @keyframes frame-to-top-right {
//   to {
//     top: 0%;
//     left: 80%;
//     transform: translate(0,0);
//   }
// }

// @keyframes frame-to-bottom-left {
//   to {
//     bottom: 0;
//     left: 0;
//     transform: translate(0,0);
//   }
// }

// .rowOuterWrapper {
//   display: flex;
//   flex-direction: column;
// }

// .rowWrapper {
//   display: flex;
//   justify-content: space-evenly;
//   align-self: stretch;
//   flex: 1 1 0;
// }

// .tbWrapper {
//   height: 200px;
//   transform: scaleY(1);
//   animation: scale-y-to-0 5s forwards;
// }

// .rlWrapper {
//   width: 300px;
//   transform: scaleX(1);
//   animation: scale-x-to-0 5s forwards;
// }

// .ProjectorScreen.ProjectorScreen-loaded {
//   flex: 1 1 0;
//   min-width: 120px;
// }

// @keyframes scale-x-to-0 {
//   to {
//     // transform: scaleX(0);
//     width: 0;
//   }
// }
// @keyframes scale-y-to-0 {
//   to {
//     // transform: scaleY(0);
//     height: 0;
//   }
// }