// @import 'variables';  // using here doesn't make it available in later scss files...
// @import 'normalize.css';  //css reset per https://create-react-app.dev/docs/adding-css-reset
                    // though says to put straight into .css file ...
@import 'utilities';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,300;1,400;1,600&family=Permanent+Marker&display=swap");

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  @include set-background-color($primary-background);
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Permanent Marker', cursive;
    letter-spacing: 2px;
  }

  p, li {
    font-size: 18px;
  }

  p {
    line-height: 1.5;
  }

  li {
    padding-bottom: 9px;
  }

  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: $font-stack;
  // Just always keep Y scroll area present to avoid weird hiccup when transition
  // from a page that had it to one that doesn't need it (and vice versa)
  overflow-y: scroll;
}

@include media-breakpoint-down(md) {
  p, li {
    font-size: 16px;
  }
}

.start-transparent {
  opacity: 0;
}

.slide-down-big {
  // transform: translateY(-150px);
  // transform: translateY(-100%);
  animation: slide-down-big 1s ease forwards;
}

.slide-up {
  animation: slide-up 1s ease forwards;
}

.slide-down {
  transform: translateY(-100%);
  animation: slide-down 1s .4s ease forwards;  
  // Note: Short delay time is good when scrolling from top, but not if refresh page while board is already in view...then loses overall timing...So could just base overall timing on purely whiteboard md being intersected, or make complex logic to see whether whiteboard sm is visible on page load, and then for those above it want to if intersected from scroll up/100%threshold...not worth it
}

// For top big board that slides down at beginning, underlying board will also be
// triggered, so give it a longer delay before starting
.slide-down-big .slide-down {
  animation-delay: 3s;
}

.slide-left {
  transform: translateX(100%);
  animation: slide-left 1s 2.5s forwards;
}

.slide-right {
transform: translateX(-100%);
animation: slide-right 1s 2.5s forwards;
}

@keyframes slide-down-big {
  from {
    transform: translateY(calc(-100vh - 100%));
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(calc(100vh + 100%));
    opacity: 1;
  } to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-100%);
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-left {
  from {
    transform: translateX(100%);
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}



// Utility classes for whiteboard transitions...
// Not sure where to put them, as they could be used by Md and Sm whiteboards,
// and currently they're getting sent down from About/Projects/Home ... convoluted
// .slide-up {
//   transform: translateY(150px);
//   animation: slide-vertical 3s ease forwards;
// }

// .slide-down {
//   // transform: translateY(-150px);
//   transform: translateY(-100%);
//   animation: slide-down 7s ease forwards;
// }

// @keyframes slide-vertical {
// to { transform: translateY(0); }
// }

// @keyframes slide-vertical {
//   from {
//     transform: translateY(500px);
//     opacity: 1;
//   } to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
// @keyframes slide-down {
//   from {
//     transform: translateY(-100%);
//     opacity: 1;
//   } to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }

// .slide-left {
//   transform: translateX(100%);
//   animation: slide-horizontal 3s ease forwards;
// }


// .slide-right {
// transform: translateX(-100%);
// animation: slide-horizontal 3s ease forwards;
// }

// @keyframes slide-horizontal {
// to { transform: translateX(0); }
// }


// I was conditionally changing slide-left/right to be slide-vertical here in css,
//  but changing the viewport width after the initial transition would then trigger
// the alterior animation...So I ended up doing the logic and checking viewport width
// in Javascript instead, that way can just assign the one static class of either
// slide-left/right or slide-up/down
//
// @include media-breakpoint-down(sm) {
//   .slide-left { //becomes slide up
//     transform: translateY(150px);
//     animation: slide-vertical 3s ease forwards;
//   }
//   .slide-right {
//     transform: translateY(-150px);
//     animation: slide-vertical 3s ease forwards;
//   }
// }

// @include media-breakpoint-down(sm) {
//   // Meant to always have y-scroll bar there so that i can always have the WhiteboardLg
//   // border flush. Whiteboard lg width at this size is 100vw
//   // body {
//   //   overflow-y: scroll; 
//   // }
// }

// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
