@import '../../utilities';

.Footer {
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;

  &-left-container {
    margin-right: auto;
    display: flex;
    align-items: center;
    // background: rgba(177, 177, 177, 0.2);

    & .Footer-horizontal-divider {
      height: 20px;
      border-right: 2px solid black;
      margin: 0 10px;
    }
    
  }

  &-link-container {
    height: 100%;
    padding: 0 10px;
  }

  &-link {
    display: flex;
    height: 100%;
    width: 60px;
    color: white;
    background: $primary-color;
    transition: transform .2s;
    border-radius: 5px;
    align-items: center;
    justify-content: center;

    & svg {
      height: 100%;
      // transform: scale(1.13);
      transform: scale(.8);
    }
  }

  // &-link-icon {
  //   height: 100%;
  //   // width: 100%;
  //   transform: scale(1.13); //to make github icon look flush...
  // }
  &-link:hover {
    // transform: scale(1.1) translateY(-2px);
    transform: scale(1.1);
  }

  
}

#copyright, .Footer-popup {
  letter-spacing: 1px;
  text-shadow: 0px 1px 0px lighten(#ceab86, 10%), 
                0px -1px 0px darken(#ceab86, 30%),
                1px 0 0px darken(#ceab86, 20%),
                // -2px 0 0px lighten(#ceab86, 5%),

                ;
  color: darken(#ceab86, 90%);
  font-weight: 600;

  // text-shadow: 0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.7);
}

// Was using standard breakpoints, but wanted more fine tune control
// given that footer stays same always
// @include media-breakpoint-down(xs) {
@media (max-width: 600px) {

  .Footer {
    height: 60px;

    &-link {
      width: 50px;

      & svg {
        height: 70%;
      }
    }

    &-left-container {
      flex-direction: column;

      & .Footer-horizontal-divider {
        border-left: 0;
        height: 2px;
        width: 20px;
        border-top: 2px solid black;
        margin: 5px 0;
      }
    }
    #copyright {
      font-size: 12px;
    }

    & &-popup {
      font-size: 12px;
    }
  }

}

@media (max-width: 420px) {
  .Footer-link {
    width: 30px;
  }
}