.Projects {
  // text-align: center;
  h1 {
    font-size: 50px;
    margin-bottom: 60px;
    text-align: center;
  }
  h2 {
    font-size: 36px;
    align-self: start;
    margin-top: 0px;
    margin-bottom: 0;
  }

  &-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  &-tech {
    font-style: italic;
    padding-left: 5px;
    border-left: 2px solid blue;
  }

  &-description {
    // font-size: 18px;
    // line-height: 1.5;
  }

  &-links {
    padding-top: 10px;
    padding-bottom: 5px; // technically wrapped in AnimatedOpacityText which is still
                        //overlaying it (transparent part) and has overflow none, so
                        //if bottom link has no padding, underline is overflow...
                        // need to redo the AnimatedOpacityText really...
    font-size: 20px;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Permanent Marker', 'Open Sans', sans-serif;
  }

  &-links-link {
    display: inline-block;
    margin: 0px 20px;
    // text-decoration: underline;
  }

  &-links-link:first-child {
    margin-bottom: 10px;
  }

  &-links-icon-demo {
    height: 22px;
    vertical-align: bottom;
  }

  &-links-icon-github {
    height: 24px;
    vertical-align: bottom;
  }
}