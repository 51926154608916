@import '../../utilities';

.WhiteboardMd {
  // margin-top: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  // overflow: hidden;
  // align-self: stretch;
  // flex: 1 1 auto;
  // width: 900px;
  width: 100%;
}

// Make small whiteboards be stacked vertically from md down
@include media-breakpoint-down(md) {
  .WhiteboardMd {
    flex-direction: column;
  }
}

// .WhiteboardMd:not(:nth-of-type(1)) {
//   margin-top: 20px;
// }

.WhiteboardMd::after {
  z-index: 100;
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  height: calc(100% + 9px);
  width: calc(100%);
  border-top: 5px solid darken(silver, 5%);
  border-bottom: 5px solid darken(silver, 5%);
  box-shadow: 0 0 4px black, 
              inset 0px 0px 7px black;
  pointer-events: none; // allows elements underneath (w/ lower z-index) to be hovered/clicked
}

// Make Odd boards seem higher than even boards
.WhiteboardMd:nth-of-type(2n+1) {
  z-index: 10;
  box-shadow: 0 0 10px 1px black, 
              inset 0 0 7px black;
}

.WhiteboardMd:nth-of-type(2n)::after {
  border-top: 5px solid darken(silver, 15%);
  border-bottom: 5px solid darken(silver, 15%);
  box-shadow: 0 0 4px black, 
              inset 0px 0px 13px black;
}

// .WhiteboardMd:nth-of-type(3) {
//   // transform: translateY(150px);
//   opacity: 0;
// }


  // .transparent {
  //   opacity: 0;
  // }

  // .slide-up {
  //   // transform: translateY(150px);
  //   animation: slide-up 1s ease forwards;
  // }
  
  // .slide-down-big {
  //   // transform: translateY(-150px);
  //   // transform: translateY(-100%);
  //   animation: slide-down-big 1s ease forwards;
  // }

  // @keyframes slide-up {
  //   from {
  //     transform: translateY(100vh);
  //     opacity: 1;
  //   } to {
  //     transform: translateY(0);
  //     opacity: 1;
  //   }
  // }
  // // .slide-down {
  // //   // transform: translateY(-150px);
  // //   // transform: translateY(-100%);
  // //   animation: slide-down 1s 2.5s ease forwards;
  // // }
  
  // @keyframes slide-down-big {
  //   from {
  //     transform: translateY(-100vh);
  //     opacity: 1;
  //   }
  //   to {
  //     transform: translateY(0);
  //     opacity: 1;
  //   }
  // }