@import '../../utilities';

.WhiteboardLg {
  width: 1400px;
  max-width: calc(83%);
  padding: $whiteboard-lg-padding-y 0px;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  // height: 100%;  // I think doesn't work b/c parent's height is dynamically determined...?
  flex: 1 1 0;
  position: relative;

  & > .WhiteboardMd:not(:last-child) {
    margin-bottom: 70px;
  }

  & > .WhiteboardSm {
    width: 100%;
    &:nth-of-type(odd) {
      z-index: 10;
    }
    &:not(:last-child) {
      margin-bottom: $whiteboard-sm_margin-bottom_not-last-child_with-lg-direct-parent;
    }
  }
}

// need ::after on wb-container to get inset box-shadow over inner white boards
// sizing is weird though, feels hacky. ::after height 100% isn't right, doesn't include borders...
.WhiteboardLg::after {
  z-index: 100;
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: calc(100% + 20px);
  border-right: 10px solid lighten(silver, 5%);
  border-left: 10px solid lighten(silver, 5%);
  border-top: 10px solid darken(silver, 5%);
  border-bottom: 10px solid darken(silver, 5%);
  box-shadow: 0 0 10px black, 
              inset 0 0 5px black;
  pointer-events: none; // allows elements underneath (w/ lower z-index) to be hovered/clicked
}


// @include media-breakpoint-down(lg) {
//   .WhiteboardLg {
//     // width: 1000px;
//   }
// }
// @include media-breakpoint-down(md) {
//   .WhiteboardLg {
//     // width: 792px;
//   }
// }

@include media-breakpoint-down(sm) {
  .WhiteboardLg {
    // Tried 100vw first, but y scrollbar popping up messed it up. Use 100% instead.
    // width: calc(100vw - 20px); // - 20px to account for ::after border
    // eh, 22 px, get a tiny bit of that black side shadow
    max-width: calc(100% - 22px);
  }
}