.WhiteboardXl {
  // overflow protection here prevents vertical and horizontal transitions from activating scrollbar
  // --tried in wbLg, but that caused overflow-x to activate under the hood in odd ways
  overflow-y: hidden;
  overflow-x: hidden;
  // padding: 50px 0;
  // color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;  // to push footer down
  // h1 {
  //   // font-size: 60px;
  // }
  // h2 {
  //   font-size: 40px;
  // }
  // h3 {
  //   font-size: 30px;
  // }

  // &-description {
  //   text-align: center;
  // }
}