@import '../../utilities';

// Use ::before and ::after elements to create overlaying boxes with bottom border
// Bottom border starts at scale(0) using left as origin, then transforms to scale(1)
// One transforms on hover, the other when clicked (component listens for click, 
// gives it special class when clicked)
// Also, clicking it keeps the hover line from scaling back to 0 even if no longer
// hovering

.AnimatedLink {
  position: relative;
  display: inline-block;
  color: $primary-color;
  text-decoration: underline $primary-color solid;

  &::after {
    content: '';
    position: absolute;
    width: calc(100% - 6px);
    height: 110%;
    top: 0px;
    left: 3px;
    border-bottom: 3px solid $primary-color;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform .2s;
  }
  &:hover::after {
    transform: scaleX(1);
  }

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 14px);
    height: 123%;
    top: 0px;
    left: 7px;
    border-bottom: 3px solid $primary-color;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform .2s;
  }

  &-animate-underline::before {
    animation: slide-underline-right .3s forwards;
  }
  &-animate-underline::after {
    transform: scaleX(1);
  }
}

@keyframes slide-underline-right {
from {
  transform: scaleX(0);
}
to {
  transform: scaleX(1);
}
}