@import '../../utilities';

.Home {
  text-align: center;
  a:hover {
    // color: yellow;
  }

  h1 {
    font-size: 50px;
    letter-spacing: 4px;
    margin: 20px 0;
  }

  h2 {
    font-size: 35px;
    letter-spacing: 2px;
  }

  & #wb-1 {
    // Hack. Current small wb min-height is 370 (should make variable).
    // When wb-2 appears beneath it, when they are equal height, wb2 box shadow
    //  suddenly doubles the darkness of wb-1's shadow. So...hack: make wb-1 
    // slightly larger. Only works when they are both at their min-heights.
    min-height: 380px;
  }

  &-wb1-slide-down {
    &-appear {
      transform: translateY(calc(-100% - #{$whiteboard-lg-padding-y}));
  
      &-active {
        transform: translateY(0);
        // duration, then delay
        // don't like having to hardcode these numbers here though...
          // maybe define classes in js to utilize props passed down...
          // The delay here refers to the animation time of the router page appearing
          //  see RoutesAnimated.scss
        transition: transform 1000ms 1000ms;
      }
  
      &-done {
        margin-bottom: $whiteboard-lg-padding-y;
        opacity: 1;
        // color: red
      }
    }
  }

  &-wb2-slide-down {
    // Second board starts under where 1st board will end up, but it starts hidden.
    // Immediately after top board finishes animation and is covering second board,
    // then reveal second board and start its downwards transition
    &-appear {
      transform: translateY(calc(-100% - 5px - #{$whiteboard-sm_margin-bottom_not-last-child_with-lg-direct-parent}));
      opacity: 0;
      &-active {
        opacity: 1;
        transform: translateY(0);
        transition: transform 1000ms 5000ms, opacity 0s 2s;  // don't like having to hardcode delay here...
      }
  
      &-done {
        opacity: 1;
        // color: red
      }
    }
  }
    // &-enter {
    //   opacity: 0;
  
    //   &-active {
    //     opacity: 1;
    //     transform: opacity 3000ms;
    //   }
  
    //   &-done {
    //     opacity: 1;
    //   }
    // }
}



// .Home {
//   // padding: 50px 0;
//   color: black;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   flex: 1 1 0;  // to push footer down
//   h1 {
//     // font-size: 60px;
//   }
//   h2 {
//     font-size: 40px;
//   }
//   h3 {
//     font-size: 30px;
//   }

//   &-description {
//     text-align: center;
//   }
// }


// #wb-1 {
//   z-index: 2;
//   transform: translateY(200px);
//   transition: 1s ease-in;
// }
// #wb-1:hover {
//   transform: translateY(0px);
// }
// #wb-2 {
//   z-index: 1;
//   transform: translateY(-250px);
//   transition: transform 1s ease-in;
// }

// #wb-2:hover {
//   transform: translateY(0px);
// }

// #wb-3 {
//   transform: translateX(0px);
//   transition: transform 1s ease-in;
// }

// #wb-3:hover {
//   transform: translateX(-200px);
// }

// #wbh-2 {
//   transform: translateY(0);
//   transition: transform 2s ease-in;
// }

// #wbh-2:hover {
//   transform: translateY(-500px)
// }

// @include media-breakpoint-down(md) {
//   .Home-whiteboard-container {
//     width: 600px;
//   }
// }
// @include media-breakpoint-down(sm) {
//   .Home-whiteboard-container {
//     width: calc(100vw - 20px);
//   }
// }