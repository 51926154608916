@import '../../utilities';


.CorkEdge {
  // currently defining height on Nav/Footer...
  z-index: 1000;
  @extend .px-1;
  background: url(../../images/utility/cork-light-tiny.jpg), #ceab86;
}

// Select first corkEdge among siblings (ie nav)
.CorkEdge:first-child {
  border-top: 5px solid silver;
  border-bottom: 2px solid silver; //allow main page to get close to this border, then box shadow will overhang it
  box-shadow: 0px 3px 0 0 silver, // first one lets it overhang over main page
              0 3px 5px black;
}

// Select last corkEdge among siblings (ie footer)
// Switch bottom/top overhang
.CorkEdge:last-child {
  border-bottom: 5px solid silver;
  border-top: 2px solid silver;
  box-shadow: 0px -3px 0 0 silver,
              0 -3px 5px black;
}