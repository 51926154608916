@import '../../utilities';

.About {
  // WEIRD CHROME BUG it seems. If apply font-weight AND font-size to a header
  // based on a CLASS rule, it ignores font-weight...kind of. It says its a valid
  // rule in the dev tools, and says it's overriding the user agent default, but
  // it LOOKS like just plain 400 font-weight all the time.
  // Works fine in safari. And just applying rule to h3 selector instead of class
  // selector works fine.
    // &-header-text {
    //   margin-top: 0;
    //   font-weight: 100;
    //   font-size: 20px;
    // }
  &-title {

    &-section-container {
      margin-bottom: 46px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      & > p, & > h1 {
        margin-right: 50px;
        margin-left: 50px;
      }
    }

    &-text {
      font-size: 50px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 0;
      // text-wrap: none;
    }

    // &-filler {
    //   flex: 0 1 200px;
    // }

    &-profile-image-container {
      position: relative;
      // margin: 0px 50px 0px 50px;
      // margin-right: auto;
      flex: 0 0 auto;
      width: 200px;
      height: 200px;
      box-shadow: 0 1px 3px rgba(0,0,0,.7);
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  
    // Tape to hold up pic
    &-profile-image-container::before {
      content: '';
      position: absolute;
      left: 1px;
      top: -11px;
      width: 10px;
      height: 40px;
      background-color: lighten(rgb(73, 92, 233), 8%);  //#6d7ded
      transform: rotate(45deg)
    }
    &-profile-image-container::after {
      content: '';
      position: absolute;
      right: 1px;
      top: -11px;
      width: 10px;
      height: 40px;
      background-color: #6d7ded;
      transform: rotate(-45deg)
    }

  }
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
    font-size: 30px;
    text-align: center;

    span {
      display: block;
      font-size: 20px;
      // color: rgb(103, 103, 103);
      // font-style: italic;
    }
  }

  ul {
    padding-left: 20px;
  }

  &-detail-text {
    // font-size: 18px;
    // line-height: 1.5;
    margin: 0;
    padding-top: 20px;

    span {
      font-size: 16px;
      color: rgb(103, 103, 103);
      font-style: italic;
    }

    @include media-breakpoint-down(md) {
      span {
        font-size: 14px;
      }
    }
  }



  @include media-breakpoint-down(450px) {
    .About-title-text {
      margin-left: 0;
      margin-right: 0;
    }
  }




  &-coding-images {
    animation: 20s linear 0s infinite normal none running codingLineup;
  }
  
  &-interests-images {
    animation: 20s linear 0s infinite normal none running interestsLineup;
  }

  &-career-window {
    position: relative;
    top: -35px;
    left: -50px;
    animation: 20s linear 0s infinite normal none running careerWindow;
  }

  &-career-images {
    display: flex;
    flex-flow: row nowrap;
    animation: 20s linear 0s infinite normal none running careerLineup;
  }

}

// keyframe name, num images (not counting duplicate at end), image height
@include vertical-slider-keyframes(codingLineup, 10, 120px);
@include vertical-slider-keyframes(interestsLineup, 6, 120px);

@keyframes careerLineup {
  /* To Uni */
  0% {
      transform: translate(0px, 0px);
  }
  5%, 10% {
      transform: translateX(-120px);
  }
  15%, 20% {
      transform: translateX(-240px);
  }
  /* To Filler */
  25% {
      transform: translate(-360px, -100px);
  }
  /* Still Filler, But adjust vertical back to baseline invisibly */
  25.1% {
      transform: translate(-360px, 0px)
  }
  /* To EMT */
  27.5%, 32.5% {
      transform: translateX(-480px);
  }
  37.5%, 42.5% {
      transform: translateX(-600px);
  }
  /* To Filler */
  47.5% {
      transform: translate(-720px,75px);
  }
  /* Still Filler, But adjust vertical back to baseline invisibly */
  47.6% {
      transform: translate(-720px, 0px);
  }
  /* To Code */
  50%, 90% {
      transform: translateX(-840px);
  }
  /* To Filler */
  98% {
      transform: translate(-720px, 35px);
  }
  100% {
      transform: translate(-720px, 0px);
  }
}

@keyframes careerWindow {
  0%, 20%, 100% {
      transform: translate(0px, 0px);
  }
  24.1%, 42.5% {
      transform: translate(50px,60px);
  }
  47.6%, 90% {
      transform: translate(100px, 30px);
  }

}

