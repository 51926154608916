// Set text color based on bg
@function set-text-color($color) {
  @if(lightness($color) > 70) {
    @return #333;
  } @else {
    @return #fff;
  }
}

// Set background & text color
@mixin set-background-color($color) {
  background-color: $color;
  color: set-text-color($color)
}