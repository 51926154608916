.Popup {
  &-container {
    position: relative;
  }

  &-button {
    background: none;
    border: none;
    font-weight: 600;
    font-size: 18px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline-color: black;
  }

  &-popup {
    background: #FFE980;
    position: absolute;
    bottom: 160%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    min-width: 300px;
    // border: 1px solid black;
    box-shadow: 0 -2px 8px 2px rgba(89, 89, 89, 0.6);

    ul {
      padding-left: 20px;
    }
    p, li {
      font-size: 16px;
      margin-top: 5px;
      line-height: 1.2;
      text-align: left;
    }
  }

  &-inner-button {
    font-weight: 600;
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: 1px solid rgba(0,0,0,.3);
    cursor: pointer;
  }

}

// Total hack, since only using for footer credits at this point, 
@media (max-width: 600px) {
  .Popup-popup {
    left: -50%;
    transform: translateX(0%);
    bottom: 330%;
  }
}