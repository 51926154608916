.slide {
  &-appear {
    transform: translateX(100vw);
    
    &-active {
      transform: translateX(0vw);
      transition: transform 1000ms ease-in;
    }
  }

  &-enter {
    transform: translateX(100vw);
    
    &-active {
      transform: translateX(0vw);
      transition: transform 1000ms ease-in;
    }

    // &-done {
    //   transform: translateX(0vw);
    //   transition: transform 100ms ease-in;
    // }
  }

  &-exit {
    transform: translateX(0);

    &-active {
      transform: translateX(-100vw);
      // delay on exit to let navcards flip. Cut delay a little short of full navcard flip time
      // currently flip is 1s
      transition: transform 1000ms 750ms ease-in; 
    }
  }

}

// &-enter#{&}-enter-active { //other gross syntax to avoid double nesting