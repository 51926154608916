@import '../../utilities';

.Nav {
  height: 40px;
  display: flex;
  // @extend .px-2;
  
  & > :first-child {
    margin-right: auto;
  }
  
  &-link {
    text-align: center;
    text-decoration: none;
    border-radius: 5px;

    // I Want to use short transition for hover, but BUG IN SAFARI
    // In safari, transitions seem to affect their properties during animations...
    // Timings are off, things sometimes transition cleanly sometimes dont.
    // Hovering over something during animation messes with it (only in safari)
    // Sooo, leave out transition for now...
      // transition: transform .2s;

    // background-image: linear-gradient(#6795fd 0%, #67ceff 100%);
  }

  &-link:hover:not(.active) {
    // background: linear-gradient(#67ceff, #6795fd);
    // background-image: linear-gradient(#6795fd 0%, #67ceff 60%);
  }

  &-scene {
    height: 100%;
    perspective: 1000px;
  }

  &-card-placeholder {
    height: 100%;
    opacity: 0;
    padding: 0 10px;
    margin: 0 10px;
    // background: green;
  }
  
  &-card {
    display: inline-block;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
  }
  
  &-card-face {
    color: rgb(215, 215, 215);
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 10px;
    border-radius: 5px;
    position: absolute;
    backface-visibility: hidden;
  }
  
  &-card-face-front {
    background: $primary-color;
  }
  
  &-card-face-back {
    background: $secondary-color;
    transform: rotateX( 180deg );
  }

  // needs to be higher in cascade (or lower specificity) than .active animation
  &-link.flip-to-non-active {
    // Had it as forwards fill, but since last keyframe has transform scale(1), 
    // that seems to block future :hover/:focus events from changing the transform
    // scale. That last keyframe just overpowers everything...
    // Turns out, don't even need it though. Maybe it was a carry over from earlier
    // experimentation
    animation: switch-nav-card-back 1s;
  }

  &-link.active {
    animation: switch-nav-card 1s forwards;
    pointer-events: none;
  }

  &-link.active.ignore-active-animation {
    transform: rotateX(180deg);
    animation: none;
  }

  // !!Current Bug in chrome that makes scale >1 to blur text...
  &-link:focus, &-link:hover {
    transform: scale(1.1);
    // color: black;
  }

}

// if want to use lower specificity instead of higher cascade 
// .flip-to-non-active {
//   animation: switch-nav-card-back 3s forwards;
// }

@keyframes switch-nav-card {
  0% {
    // use 1.0 instead if hover effect is not already scaling up a bit
    transform: scale(1.1);
  }
  // and use this keyframe if starting from scale(1.0), or just want to flip at same time no matter what
  20% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1.1) rotateX(180deg);
  }
  100% {
    transform: scale(1) rotateX(180deg);
  }
}
@keyframes switch-nav-card-back {
  0% {
    transform: scale(1) rotateX(180deg);
  }
  20% {
    transform: scale(1.1) rotateX(180deg);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// FOR SIMPLE NAVBAR- without 3d transitions
// .Nav {
//   display: flex;
//   // @extend .px-2;
//   // // @extend .py-1;

//   .active {
//     // color: red;
//     // background-image: linear-gradient(#ff0101 0%, #ff7777 100%);
//     background-image: linear-gradient(blue, blue);
//     // background-image: linear-gradient(red 0%, lighten(red, 20%) 50%, lighten(red,25%) 70%, lighten(red,20%) 90%, lighten(red, 15%) 100%);
//     pointer-events: none;
//   }
  
//   :first-child {
//     margin-right: auto;
//   }
  
//   // :not(:first-child):not(:last-child) {  //this worked in terms of selecting only the middle things, but ended up not liking the styling
//   //     margin-right: 10px;
//   //   }
//   &-link {
//     text-decoration: none;
//     // background-image: linear-gradient(#6795fd 0%, #67ceff 100%);

//     border-radius: 5px;
//     @extend .px-2;
//     @extend .py-1;
//     @extend .mx-1;
//   }

//   &-link:hover:not(.active) {
//     // background: linear-gradient(#67ceff, #6795fd);
//     // background-image: linear-gradient(#6795fd 0%, #67ceff 60%);

//   }

//   // &-link:hover.active {
//   //   background-color: blue;
//   // }
// }